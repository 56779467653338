import { useAuth0 } from "@auth0/auth0-react";
import {
  ActiveItemTypeConstant,
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IAuth0User,
  ICoxUser,
  IDealershipComboData,
  IUser,
  StorageConstant,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchProvider } from "../../../contexts";
import { useC4u, useSessionContext } from "../../../hooks";
import { Container } from "./main.template.style";

export const MainAuth = (props: any) => {
  const { t } = useTranslation();

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { token, setToken } = useSessionContext();

  const { GetAuth0User, GetUser, postSelectDealership, getListMyDealerships } =
    useC4u();

  useEffect(() => {
    window.addEventListener(
      "message",
      function (event) {
        if (event.data && event.data.eventName === "c4u::toggleMenu") {
          console.log("recebido evento!");
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? "",
        scope: process.env.REACT_APP_AUTH0_SCOPE ?? "",
        redirectUri: `${window.location.origin}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  //#region auth
  const [auth0User, setAuth0User] = useState<IAuth0User>();
  const [coxUser, setCoxUser] = useState<ICoxUser>();
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);
  const [selectedDealership, setSelectedDealership] =
    useState<IDealershipComboData>();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently().then((t) => setToken(t));
      GetAuth0User().then((t) => setAuth0User(t));
      GetUser().then((t) => setCoxUser(t));
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (token) {
      GetAuth0User().then((t) => setAuth0User(t));
      GetUser().then((t) => setCoxUser(t));
    }
    // eslint-disable-next-line
  }, [token]);

  const [userCox, setUserCox] = useState<IUser>();
  useEffect(() => {
    if (coxUser && auth0User) {
      setUserCox(AuthHelper.GetCoxUser(coxUser, auth0User));
      const { dealershipId, dealershipName, dealershipCnpj } = coxUser;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealership({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
  }, [auth0User, coxUser]);
  //#endregion

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  return (
    <>
      <C4UMenu
        handleLogout={handleLogout}
        activeMenu={ActiveItemTypeConstant.KBB}
        environment={process.env.REACT_APP_BUILD}
        user={userCox}
        selectDealershipProps={
          !userCox
            ? undefined
            : {
                userId: coxUser?.id,
                userIsManager:
                  coxUser?.corporateUserType === CorporateUserTypeEnum.Manager,
                userIsCorporate: coxUser?.isCorporate,
                dealership: selectedDealership,
                onSubmitCallback: postSelectDealership,
                getDealershipsPagedListCallback: getListMyDealerships,
                setSelectedDealership: setSelectedDealership,
                onHideCallback: clearKeysFromStorage,
                forceShowModal: redirectedFromLogin,
              }
        }
        translate={t}
        dealershipUser={coxUser?.dealershipCnpj}
      />
      <Container>
        {(token || isAuthenticated) && (
          <SearchProvider>{props.children}</SearchProvider>
        )}
      </Container>
    </>
  );
};
